import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Link from '../components/Link';

function MemberPermissionText({ pageContext, location }) {
  const permissionText = pageContext?.permissionText?.data?.WebDisclosureRequirementText;
  const title = 'Üyelik Aydınlatma Metni - Tıkla Gelsin';
  const metaData = 'Tıkla Gelsin üyelik aydınlatma metnine bu sayfadan ulaşabilirsiniz.';

  return (
    <Layout sidebar={false} location={location} hasBreadCrumb>
      <SEO title={title} description={metaData} />
      <div className="kvkk-page">
        <div dangerouslySetInnerHTML={{ __html: permissionText }} />

        <Link to="/" className="go-back">
          Ana Sayfaya Dön
        </Link>
      </div>
    </Layout>
  );
}

MemberPermissionText.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default MemberPermissionText;
